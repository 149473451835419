import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ContentBlock from "../components/content-block"
import './about.scss'

import Layout from '../components/layout'

const About = ({data}) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter: { title, mainImage }, html } = markdownRemark
  return (
    <Layout>
      <ContentBlock wrapper>
      <div className="about">
        <h1>{title}</h1>
        <div className="about-wrapper">
          <div className="about-main-image">
            <img src={mainImage} />
          </div>
          <div
            className="about-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      </ContentBlock>
    </Layout>
  );
};
export const pageQuery = graphql`
  query AboutQuery {
    markdownRemark(fileAbsolutePath: {regex : "\/about/"}) {
      html
      frontmatter {
        path
        title
        mainImage
      }
    }
  }
`;

export default About;
